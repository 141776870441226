import React from "react";
import { RainbowMenu } from "../components/RainbowMenu";
import { Sidebar } from "../components/Sidebar";
import { LogOpen } from "../components/LogOpen";
import { Helmet } from "react-helmet";

import "./styles.scss"

export default function PostsPage() {

  const links = [
    {
      href: "https://jugendinitiative.com",
      fav: "https://img1.wsimg.com/isteam/ip/3e79f1f5-8e75-4c79-bfd5-393fedecbe3b/favicon/55bcb583-2bc9-45ef-a6dc-96a3438e181c.png/:/rs=w:32,h:32,m",
      title: "Jugendinitiative bei Fliederlich e.V. - Nürnberg",
      description: "Jugendtreff für queere* Jugendliche zwischen 14 - 27 Jahren."
    },
    {
      href: "https://www.gaycon.de",
      fav: "https://www.gaycon.de/s/misc/favicon.png?1480005066=",
      title: "gaycon - Queeres Stadtleben",
      description: "Queeres Online-Magazin für Nürnberg & Franken."
    },
    {
      href: "https://uferlos-bamberg.de",
      fav: "https://uferlos-bamberg.de/sites/default/files/favicon.ico",
      title: "Uferlos e.V. - Schwule und Lesben in Bamberg",
      description: "Unser Nachbar-Verein in Bamberg."
    },
    {
      href: "http://www.rosapanther.de",
      fav: "http://www.rosapanther.de/wp-content/uploads/2015/10/rosapanther.png",
      title: "Rosa Panther e.V. Nürnberg",
      description: "Schwul-Lesbischer Sportverein in Nürnberg."
    },
    {
      href: "https://www.queergottesdienstnuernberg.de/",
      fav: "http://www.queergottesdienstnuernberg.de/templates/beez3/favicon.ico",
      title: "Queergottesdienst Nürnberg",
      description: "Einmal im Monat findet an einem Sonntagabend der QueerGottesdienst statt. Ihr seid alle herzlich eingeladen. Auf dieser Seite findet ihr alle notwendigen Informationen."
    },
    {
      href: "http://www.queercn.de/",
      fav: "http://www.queercn.de/img/favicon.ico",
      title: "Queer Culture Nürnberg e.V.",
      description: "Gemeinnütziger Kulturverein zur Förderung der queer-en Kultur in Nürnberg und der Metropolregion"
    },
  ]

  return <div>
    <Helmet>
      <title>Links</title>
    </Helmet>
    <LogOpen page="links" />
    <RainbowMenu active="Links" relativePath="../"></RainbowMenu>

        <div className="columns">
          <div className="column is-3">
            <Sidebar></Sidebar>
          </div>
          <div className="column is-9">
            <h2 className="title is-4">Links</h2>
            {links.map(link =>
              <a href={link.href} target="_blank">
                <div className="card has-background-light mb-5">
                  <div className="card-header">
                    <p className="card-header-title">
                    <figure className="image is-32x32 mr-3">
                      <img src={link.fav}/>
                    </figure> {link.title}</p>
                  </div>
                  <div className="card-content">
                    <div className="content">
                      {link.description}
                    </div>
                  </div>
                </div>
              </a>
            )}
          </div>
        </div>


  </div>;
}